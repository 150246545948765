
import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Stack,
  Grid,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Box,
  Heading,
  Text,
  Textarea,
  theme,
} from '@chakra-ui/core';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import { validateTextNoSpecial } from '../logic/formvalidator';
import { CssGameText } from '../textfiles/gametexts';
import { FormText } from '../textfiles/maintexts';


const Start = ({ startGame }) => {
  return (
    <>
      <Heading>{CssGameText.ready}</Heading>
      <Button
        mt={4}
        // variantColor={buttonColor}
        onClick={() => startGame()}
        // type="submit"
      >
        {CssGameText.button.start}
      </Button>
    </>
  );
};
Start.propTypes = {
  startGame: PropTypes.func.isRequired,
};

const Win = ({ restartGame, resetGame }) => {
  return (
    <>
      <Heading>{CssGameText.win}</Heading>
      <Button
        mt={4}
        // variantColor={buttonColor}
        onClick={() => restartGame()}
        // type="submit"
      >
        {CssGameText.button.restart}
      </Button>
      <Button
        mt={4}
        // variantColor={buttonColor}
        onClick={() => resetGame()}
        // type="submit"
      >
        {CssGameText.button.menu}
      </Button>
    </>
  );
};
Win.propTypes = {
  restartGame: PropTypes.func.isRequired,
};

const GameOver = ({ restartGame }) => {
  return (
    <>
      <Heading>{CssGameText.gameover}</Heading>
      <Button
        mt={4}
        // variantColor={buttonColor}
        // isLoading={restartGame}
        onClick={() => restartGame()}
        type="submit"
      >
        {CssGameText.button.restart}
      </Button>
    </>
  );
};
GameOver.propTypes = {
  restartGame: PropTypes.func.isRequired,
};

const Level1 = ({ onSuccess, onFail }) => {
  return (
    <>
      <Heading>{CssGameText.level.lvl1}</Heading>
      <Button
        mt={4}
        // variantColor={buttonColor}
        // isLoading={onSuccess(1)}
        onClick={() => onSuccess()}
      >
        {CssGameText.button.next}
      </Button>
    </>
  );
};
Level1.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
};

const Level2 = ({ onSuccess, onFail, final }) => {
  return (
    <>
      <Heading>{CssGameText.level.lvl2}</Heading>
      <Button
        mt={4}
        onClick={() => onFail()}
        type="submit"
      >
        {CssGameText.button.next}
      </Button>
      <Button
        mt={4}
        onClick={() => onSuccess(null, !!final)}
      >
        {CssGameText.button.next}
      </Button>
    </>
  );
};
Level2.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
  final: PropTypes.bool,
};
Level2.defaultProps = {
  final: false,
};

class MenuForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
  }

  submitForm(values, setSubmitting) {
    const { formFinished } = this.props;
    setSubmitting(false);
    formFinished(values);
  }

  render() {
    const honeyStyle = {
      opacity: 0,
      top: 0,
      left: 0,
      height: 0,
      width: 0,
      zIndex: -1,
    };
    return (
      <Formik
        initialValues={{ player: '' }}
        onSubmit={(values, { setSubmitting }) => this.submitForm(values, setSubmitting)}
      >
        {(props) => (
          <Form style={{ width: '90vw', maxWidth: '650px' }}>
            <Grid gap={3}>
              <Box>
                <Field name="surname">
                  {({ field }) => (
                    <FormControl style={honeyStyle }>
                      <FormLabel htmlFor="surname" style={honeyStyle}>
                        {FormText.surname.label}
                      </FormLabel>
                      <Input
                        {...field}
                        id="surname"
                        autocomplete="off"
                        placeholder={FormText.surname.helper}
                        style={honeyStyle}
                      />
                    </FormControl>
                  )}
                </Field>
                <Field name="player" validate={(val) => validateTextNoSpecial(val, true)}>
                  {({ field, form }) => (
                    <FormControl isInvalid={form.touched.player && form.errors.player} isRequired>
                      <FormLabel htmlFor="player">
                        {FormText.name.label}
                      </FormLabel>
                      <Input {...field} id="player" placeholder={FormText.name.helper} />
                      <FormErrorMessage>
                        {form.errors.player}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Button
                  mt={4}
                  // variantColor={buttonColor}
                  isLoading={props.isSubmitting}
                  type="submit"
                >
                  {CssGameText.button.validate}
                </Button>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>
    );
  }
}
MenuForm.propTypes = {
  formFinished: PropTypes.func.isRequired,
};

class CssGame extends React.Component {
  constructor(props) {
    super(props);

    this.onMenuValidation = this.onMenuValidation.bind(this);
    this.resetGame = this.resetGame.bind(this);
    this.restartGame = this.restartGame.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onFail = this.onFail.bind(this);

    this.state = {
      player: 'Joueur 1',
      score: 0, // check local storage
      level: 1,
      step: null,
    };
  }

  componentDidMount() {
    this.resetGame();
  }

  onMenuValidation(values) {
    console.log("CssGame -> onMenuValidation -> values", values)
    this.setState({ ...values, step: 'start' });
  }

  onSuccess(scores, end) {
    console.log("CssGame -> onSuccess -> end", end)
    this.setState((prevState) => {
      let { step } = prevState;
      if (end) {
        step = 'win';
      } else if (step === 'start') {
        step = 'lvl_1';
      } else {
        step = `lvl_${parseInt(step.split('_')[1], 10) + 1}`;
      }
      return { step };
    });
  }

  onFail(scores) {
    this.setState({ step: 'over' });
  }

  resetGame() {
    this.setState({
      player: 'Joueur 1',
      score: 0, // check local storage
      level: 1,
      step: 'reset',
    });
  }

  restartGame() {
    this.setState({
      score: 0, // check local storage
      level: 1,
      step: 'start',
    });
  }

  renderStep() {
    const { step } = this.state;
    let content = null;
    switch (step) {
      case 'over':
        content = <GameOver restartGame={this.restartGame} />;
        break;
      case 'start':
        content = <Start startGame={this.onSuccess} />;
        break;
      case 'win':
        content = <Win restartGame={this.restartGame} resetGame={this.resetGame} />;
        break;
      case 'lvl_1':
        content = <Level1 onSuccess={this.onSuccess} onFail={this.onFail} />;
        break;
      case 'lvl_2':
        content = <Level2 onSuccess={this.onSuccess} onFail={this.onFail} final />;
        break;
      case 'reset':
      default:
        content = <MenuForm formFinished={this.onMenuValidation} />;
        break;
    }
    return content;
  }

  render() {
    const content = this.renderStep();
    return (
      <Box
        // onMouseEnter={() => onMouseOver(true)}
        // onMouseLeave={() => onMouseOver(false)}
        borderWidth="5px"
        rounded="md"
        style={{
          margin: '12px',
          maxWidth: '900px',
          minWidth: '350',
          width: '95vw',
        }}
      >
        {content}
      </Box>
    );
  }
}

export default CssGame;
