import React from 'react';

import Layout from '../components/display/layout';
import SEO from '../components/seo';
import CssGame from '../components/games/cssgame_manager';

import { CssGameText } from '../components/textfiles/gametexts';

const CssGamePage = () => (
  <Layout>
    <SEO title={CssGameText.title} />
    <CssGame />
  </Layout>
);

export default CssGamePage;
